import {
  Box,
  Button,
  Card,
  CardContent,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  FormControlLabel,
  List,
  ListItem,
  ListItemText,
  Radio,
  RadioGroup,
  Typography,
  colors,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { PaymentMethod } from "../../models/PaymentMethodModel";
import { CartModel } from "../../models/CartModel";
import { UseFormHandleSubmit } from "react-hook-form";
import { CheckoutAddressModel } from "../../models/CheckoutAddressModel";
import { ProfileModel } from "../../models/ProfileModel";
import { useFormatCurrency } from "../../hooks/useFormatCurrency";
import {
  usePlaceOrderMutation,
  useRequestPaymentDataMutation,
} from "../../services/CheckoutServices";
import { StripeContainer } from "./StripeContainer";
import { ResponseModel } from "../../models/ResponseModel";
import { showToastMessage, snakeToCamel } from "../../common/Helper";
import { StripeInitModel } from "../../models/StripeInitModel";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { StateModel } from "../../models/StateModel";
import { QuotationModel } from "../../models/QuotationModel";
import { FileModel } from "../../models/FileModel";
import { BankReciptUpload } from "./BankReciptUpload";
import { PlaceOrderModel } from "../../models/PlaceOrderModel";
import { useNavigate } from "react-router-dom";
import useInputDialog from "../../hooks/useInputDialog";
import { PoNumberField } from "./PoNumberField";
import { EditProfileFormModel } from "../../models/EditProfileFormModel";
import { useProfileUpdate } from "../../hooks/useProfileUpdate";

interface Props {
  styles: any;
  products: CartModel[];
  profile: ProfileModel;
  handleSubmit: UseFormHandleSubmit<CheckoutAddressModel, undefined>;
  state: StateModel;
  quotation: QuotationModel;
  isSaveBillingAddress: boolean;
  isSaveDeliveryAddress: boolean;
}

const PaymentMethods: PaymentMethod[] = [
  {
    value: "CARD",
    label: "Card Payment",
  },
  {
    value: "BANK",
    label: "Bank Transfer",
  },
  {
    value: "CREDIT",
    label: "Net 30 Day Account Credit",
  },
];

export const PaymentDetails: React.FC<Props> = (props) => {
  let styles = props.styles;
  let profile = props.profile;
  let quotation = props.quotation;
  let tempPricesMap = new Map<number, number>();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { formatCurrency } = useFormatCurrency();

  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    PaymentMethods[0].value
  );
  const [getStripeInitData] = useRequestPaymentDataMutation();
  const [placeOrder] = usePlaceOrderMutation();
  const { updateProfile } = useProfileUpdate();
  const [stripeInitData, setStripeInitData] = useState<StripeInitModel>();
  const [reciptFile, setReciptFile] = useState<FileModel>();
  const [placeOrderModel, setPlaceOrderModel] = useState<PlaceOrderModel>();
  const [PoNumberDialog, getPoNumber] = useInputDialog(PoNumberField);
  const [productPricesMap, setProductPricesMap] = useState(tempPricesMap);
  const [checkoutAddressModel, setCheckoutAddressModel] =
    useState<CheckoutAddressModel>();

  const currency = profile.country.currency;
  const isCreditCustomer = profile.isCreditCustomer;
  const isTaxIncluded = profile.country.isTaxIncluded;
  const taxPercentage = isTaxIncluded
    ? profile.country.taxPercentage
    : props.state.taxPercentage;
  // const hasPhysicalProduct = products.some((x) => !x.isDigitalGood);
  const taxType = isTaxIncluded ? "GST" : "Regional TAX";

  useEffect(() => {
    if (quotation) {
      quotation.products.forEach((p) => {
        tempPricesMap.set(p.id, p.displayPrice);
      });
      console.log(tempPricesMap);
      setProductPricesMap(tempPricesMap);
    }
  }, [quotation]);

  async function continueToPayment(addressAndContact: CheckoutAddressModel) {
    setCheckoutAddressModel(addressAndContact);
    let poNumber = await getPoNumber();
    dispatch(setIsLoading(true));
    let tempPlaceOrderModel: PlaceOrderModel = {
      contactName:
        addressAndContact.billingFirstName +
        " " +
        addressAndContact.billingLastName,
      contactPhone: addressAndContact.billingPhone,
      paymentMethod: selectedPaymentMethod,
      paymentStatus: "PENDING",
      paymentProof: reciptFile?.path ?? "",
      billingAddress1: addressAndContact.billingAddress1,
      billingAddress2: addressAndContact.billingAddress2,
      paymentIntentId: stripeInitData?.paymentIntentId!,
      token: quotation?.token!,
      ...(addressAndContact.deliveryFirstName.trim() !== "SAMEASBILLING" && {
        orderDeliveryDetails: {
          contact_phone: addressAndContact.deliveryPhone!,
          address1: addressAndContact.deliveryAddress1,
          address2: addressAndContact.deliveryAddress2,
          state_id: props.state.id,
          first_name: addressAndContact.deliveryFirstName,
          last_name: addressAndContact.deliveryLastName,
          // saving delivery details for future orders
          enable_reuse: props.isSaveDeliveryAddress ? 1 : 0,
        },
      }),
      poNumber: poNumber,
    };
    setPlaceOrderModel(tempPlaceOrderModel);
    if (selectedPaymentMethod === "CARD") {
      getStripeInitData({ token: quotation?.token! })
        .then((initResponse: any) => {
          let response = initResponse.data as ResponseModel;
          if (response.status === "success") {
            let tempStripeInitModel = snakeToCamel(
              response.response
            ) as StripeInitModel;
            tempPlaceOrderModel.paymentStatus = "PAID";
            tempPlaceOrderModel.paymentIntentId =
              tempStripeInitModel.paymentIntentId;
            setPlaceOrderModel(tempPlaceOrderModel);
            setStripeInitData(tempStripeInitModel);
            dispatch(setIsLoading(false));
          }
        })
        .catch(() => {
          showToastMessage("error", "somethig went wrong!");
          dispatch(setIsLoading(false));
        });
    } else {
      handlePlaceOrder(true, tempPlaceOrderModel, addressAndContact);
    }
  }

  async function handlePlaceOrder(
    status: boolean,
    orderDetails = placeOrderModel,
    checkoutAddress = checkoutAddressModel
  ) {
    if (status && orderDetails) {
      setStripeInitData(undefined);
      dispatch(setIsLoading(true));
      placeOrder(orderDetails!)
        .then((res: any) => {
          let response = res.data as ResponseModel;
          showToastMessage(response.status, response.message);
          // Save address for reseller profile.
          if (props.isSaveBillingAddress && checkoutAddress) {
            let profileFormModel: EditProfileFormModel = {
              firstName: profile.firstName,
              lastName: profile.lastName,
              address1: checkoutAddress.billingAddress1,
              address2: checkoutAddress.billingAddress2,
              phone: profile.phone,
              state: props.state.id.toString(),
            };
            updateProfile(profileFormModel, props.state);
          }
          navigate("/orders");
          dispatch(setIsLoading(false));
        })
        .catch((error) => {
          showToastMessage("error", "somethig went wrong\n" + error);
          dispatch(setIsLoading(false));
        });
    } else {
      showToastMessage("error", "somethig went wrong!");
      dispatch(setIsLoading(false));
    }
  }

  return (
    <Card sx={styles.container} variant="outlined">
      <CardContent>
        <List sx={styles.wrapper}>
          {quotation &&
            quotation.products.map((product, index) => {
              let price = productPricesMap.get(product.id)! * product.quantity;

              return (
                <React.Fragment key={index}>
                  <ListItem>
                    <ListItemText
                      primary={product.name}
                      secondary={
                        product.attributes + "   x  " + product.quantity
                      }
                      secondaryTypographyProps={styles.quantity}
                    />
                    <ListItemText
                      primary={formatCurrency(price)}
                      primaryTypographyProps={styles.value}
                    />
                  </ListItem>
                  {product.discountAmount && product.discountPercentage && (
                    <ListItem>
                      <ListItemText
                        primary={`${product.discountPercentage}% Discount`}
                      />
                      <ListItemText
                        primary={`(-${formatCurrency(product.discountAmount)})`}
                        primaryTypographyProps={styles.value}
                      />
                    </ListItem>
                  )}
                  {props.products.length - 1 !== index && <Divider />}
                </React.Fragment>
              );
            })}
        </List>
        <List sx={styles.wrapper}>
          {quotation?.isDeliveryApplicable && (
            <ListItem>
              <ListItemText
                primary={"Delivery Fee"}
                secondary={
                  quotation.isExpressShipping ? "Express Shipping" : ""
                }
              />
              <ListItemText
                primary={
                  quotation?.deliveryFee === 0
                    ? "Free"
                    : formatCurrency(quotation?.deliveryFee)
                }
                primaryTypographyProps={{
                  ...styles.value,
                  ...(quotation.deliveryFee === 0 && {
                    color: colors.green[500],
                  }),
                }}
              />
            </ListItem>
          )}
          {quotation?.isFreeShipping &&
          quotation.products.some((x) => !x.isDigitalGood) ? (
            <ListItem>
              <ListItemText primary={"Delivery Fee"} />
              <Chip color="success" label={"FREE"} />
            </ListItem>
          ) : (
            <></>
          )}
          <Divider />
          <ListItem>
            <ListItemText primary={`${taxType} (${taxPercentage}%)`} />
            <ListItemText
              primary={"+" + formatCurrency(quotation?.totalTax!)}
              primaryTypographyProps={styles.value}
            />
          </ListItem>
          <Divider />
          <ListItem>
            <ListItemText
              primary={"Total (" + currency + ")"}
              primaryTypographyProps={styles.total}
            />
            <ListItemText
              primary={quotation?.grandTotal!.toFixed(2)}
              primaryTypographyProps={{ ...styles.value, ...styles.total }}
            />
          </ListItem>
        </List>

        <Typography variant="h5">Select Payment Method</Typography>
        <Box sx={styles.paymentMethods}>
          <RadioGroup
            row
            value={selectedPaymentMethod}
            onChange={(event) => {
              setSelectedPaymentMethod(event.target.value);
            }}
          >
            {PaymentMethods.map((method, index) => (
              <FormControlLabel
                key={index}
                value={method.value}
                control={<Radio />}
                label={method.label}
                disabled={!isCreditCustomer && method.value == "CREDIT"}
              />
            ))}
          </RadioGroup>
          {selectedPaymentMethod === "BANK" && (
            <BankReciptUpload
              setReciptFile={setReciptFile}
              reciptFile={reciptFile!}
              styles={styles.uploadRecipt}
            />
          )}
        </Box>
        <Button
          sx={styles.continueButton}
          fullWidth
          type="button"
          variant="contained"
          onClick={props.handleSubmit(continueToPayment)}
        >
          Continue
        </Button>
      </CardContent>
      <Dialog
        open={!!stripeInitData}
        fullWidth
        onClose={() => {
          setStripeInitData(undefined);
        }}
      >
        {stripeInitData && (
          <DialogContent>
            <StripeContainer
              initData={stripeInitData}
              callback={handlePlaceOrder}
            />
          </DialogContent>
        )}
      </Dialog>
      <PoNumberDialog />
    </Card>
  );
};
