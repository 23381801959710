import React from "react";
import { OtherResellerModel } from "../../models/OtherResellerModel";
import {
  Button,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
} from "@mui/material";
import { useForm } from "react-hook-form";
import { ControlledTextField } from "../shared/ControlledTextField";
import { yupResolver } from "@hookform/resolvers/yup";
import { OtherResellersSchema } from "../../schemas/OtherResellersSchema";
import {
  useEditOtherResellerMutation,
  usePostOtherResellerMutation,
} from "../../services/ProfileServices";
import { useDispatch } from "react-redux";
import { setIsLoading } from "../../reduxenv/Slices/AppSlice";
import { ResponseModel } from "../../models/ResponseModel";
import { showToastMessage } from "../../common/Helper";

interface Props {
  handleAddReseller(): void;
  reseller?: OtherResellerModel;
}

export const AddEditOtherResellerForm: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const [registerReseller] = usePostOtherResellerMutation();
  const [updateReseller] = useEditOtherResellerMutation();
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<OtherResellerModel>({
    resolver: yupResolver(OtherResellersSchema),
    defaultValues: props.reseller
      ? props.reseller
      : {
          firstName: "",
          lastName: "",
          subCompanyName: "",
          memberId: "",
          email: "",
          phone: "",
          address1: "",
          address2: "",
        },
  });

  async function submit(otherResellerModel: OtherResellerModel) {
    dispatch(setIsLoading(true));
    if (otherResellerModel.subCompanyName === "") {
      otherResellerModel.subCompanyName = null;
    }
    if (otherResellerModel.memberId === "") {
      otherResellerModel.memberId = null;
    }
    if (props.reseller) {
      try {
        const result: any = await updateReseller(otherResellerModel);
        let responseModel = result.data as ResponseModel;
        if (responseModel.status === "success") {
          showToastMessage(responseModel.status, responseModel.message);
          props.handleAddReseller();
        }
      } catch {}
      dispatch(setIsLoading(false));
    } else {
      registerReseller(otherResellerModel)
        .then((response: any) => {
          let responseModel = response.data as ResponseModel;
          if (responseModel.status === "success") {
          }
          showToastMessage(responseModel.status, responseModel.message);
          props.handleAddReseller();
          dispatch(setIsLoading(false));
        })
        .catch(() => {
          dispatch(setIsLoading(false));
        });
    }
  }

  return (
    <DialogContent>
      <DialogTitle>Add Reseller</DialogTitle>
      <Grid
        container
        spacing={2}
        component={"form"}
        onSubmit={handleSubmit(submit)}
      >
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="firstName"
            label="First Name*"
            error={errors.firstName}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="lastName"
            label="Last Name*"
            error={errors.lastName}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="memberId"
            label="Member Id"
            error={errors.memberId}
          />
          <FormHelperText>
            Leave blank if employee of parent company
          </FormHelperText>
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="subCompanyName"
            label="Sub Company Name"
            error={errors.subCompanyName}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="email"
            label="Email Address*"
            error={errors.email}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="address1"
            label="Address line 1*"
            error={errors.address1}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="address2"
            label="Address line 2*"
            error={errors.address2}
          />
        </Grid>
        <Grid item xs={12}>
          <ControlledTextField
            control={control}
            name="phone"
            label="Phone*"
            error={errors.phone}
          />
        </Grid>
        <Grid item xs={12}>
          <Button type="submit" variant={"contained"} fullWidth>
            {props.reseller ? "Update" : "Add"}
          </Button>
        </Grid>
      </Grid>
    </DialogContent>
  );
};
