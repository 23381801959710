import React from "react";
import { OtherResellerModel } from "../../models/OtherResellerModel";
import { Edit, PersonRounded, Remove } from "@mui/icons-material";
import {
  IconButton,
  Avatar,
  ListItem,
  ListItemText,
  ListItemIcon,
  Typography,
} from "@mui/material";
import { red } from "@mui/material/colors";
import { AppColors } from "../../common/AppColors";

interface Props {
  index: number;
  reseller: OtherResellerModel;
  removeReseller(resellerId: number, index: number): void;
  handleClickEdit(): void;
}

export const OtherResellerCard: React.FC<Props> = (props) => {
  let reseller = props.reseller;

  function handleClickRemove(event: React.MouseEvent<HTMLButtonElement>) {
    event.stopPropagation();
    props.removeReseller(reseller.id!, props.index);
  }

  return (
    <ListItem>
      <ListItemIcon>
        <PersonRounded />
      </ListItemIcon>
      <ListItemText
        primary={
          <div>
            {reseller.firstName + " " + reseller.lastName}
            <br />
            {reseller.subCompanyName && (
              <Typography variant="button" color={AppColors.DarkGray}>
                {reseller.subCompanyName}{" "}
                {reseller.memberId && " | #" + reseller.memberId}
              </Typography>
            )}
          </div>
        }
        secondary={
          <div>
            {reseller.email} <br />{" "}
            {reseller.address1 + " " + reseller.address2}
          </div>
        }
      />
      <ListItemIcon>
        <IconButton onClick={props.handleClickEdit}>
          <Edit fontSize="small" />
        </IconButton>
        <IconButton onClick={handleClickRemove}>
          <Avatar
            sx={{
              width: 20,
              height: 20,
              bgcolor: red[500],
            }}
          >
            <Remove />
          </Avatar>
        </IconButton>
      </ListItemIcon>
    </ListItem>
  );
};
